"use client";

import React, { useState } from "react";
import Chart from "react-apexcharts";

function BalanceDetails() {
  const [options] = useState({
    chart: {
      height: 250,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      show: true,
    },
    colors: [
      "var(--chart-color1)",
      "var(--chart-color2)",
      "var(--chart-color3)",
      "var(--chart-color4)",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [series] = useState([44, 55, 41, 17]);

  return (
    <div className="container-fluid p-4">
      <div className="card border-0">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="balance-info h-100 d-flex flex-column py-3">
                <div>
                  <div className="text-muted mb-2">Account balance:</div>
                  <h3 className="mb-2">0.00000000</h3>

                  <div className="text-muted mb-2">Estimated Value:</div>
                  <h5 className="mb-2">$00000.00</h5>
                </div>

                {/* <div className="mb-4">
                  <div className="text-uppercase text-muted mb-2">
                    Buy this month
                  </div>
                  <h5 className="mb-0">0.00000000</h5>
                </div>

                <div className="mb-4">
                  <div className="text-uppercase text-muted mb-2">
                    Sell this month
                  </div>
                  <h5 className="mb-0">0.00000000</h5>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="chart-container d-flex align-items-center justify-content-center"
                style={{ height: "400px" }}
              >
                <Chart
                  options={options}
                  series={series}
                  type="donut"
                  height="350"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceDetails;
