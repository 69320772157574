import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import ConfirmationPopup from "../Ui Component/ConfirmationPopup";

function WithdrawCrypto() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("USDT");

  const handleConfirm = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleClose = () => setShowConfirmation(false);

  const handleProceed = () => {
    console.log("Withdrawal confirmed");
    setShowConfirmation(false);
  };

  return (
    <div className="container-fluid p-4">
      <form onSubmit={handleConfirm}>
        <div className="row g-4">
          {/* Coin Selection */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">Select coin</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter amount"
                onChange={() => {}}
              />
              <Dropdown.Toggle
                className="btn btn-outline-secondary"
                variant="outline-secondary"
              >
                {selectedCoin}
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                {["BNB", "BTC", "BUSD", "EOS", "ETH"].map((coin) => (
                  <Dropdown.Item
                    key={coin}
                    onClick={() => setSelectedCoin(coin)}
                  >
                    {coin}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </div>
          </div>

          {/* Withdraw Address */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">
              Withdraw Address
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter withdrawal address"
            />
          </div>

          {/* Network Selection */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">
              Select Withdraw Network
            </label>
            <select className="form-select">
              <option>BNB (Arrival time ≈ 2 mins)</option>
              <option>BTC (Arrival time ≈ 1 mins)</option>
              <option>BSC (Arrival time ≈ 2 mins)</option>
              <option>ETH (Arrival time ≈ 3 mins)</option>
            </select>
          </div>

          {/* Transaction Details */}
          <div className="col-12">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="p-3 border rounded">
                  <div className="text-muted mb-2">BTC spot balance</div>
                  <div className="fw-bold">0 BTC</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-3 border rounded">
                  <div className="text-muted mb-2">Minimum withdrawal</div>
                  <div className="fw-bold">0.0000086 BTC</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-3 border rounded">
                  <div className="text-muted mb-2">Network fee</div>
                  <div className="fw-bold">0.0000043 ~ 0.00055 BTC</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-3 border rounded">
                  <div className="text-muted mb-2">24h remaining limit</div>
                  <div className="fw-bold">100 BTC/100 BTC</div>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary w-100 py-3 text-uppercase fw-bold"
            >
              Confirm Withdrawal
            </button>
          </div>
        </div>
      </form>

      {/* Confirmation Modal */}
      <ConfirmationPopup
        show={showConfirmation}
        onHide={handleClose}
        onConfirm={handleProceed}
        title="Confirm Withdrawal"
        body="Are you sure you want to proceed with this withdrawal?"
      />
    </div>
  );
}

export default WithdrawCrypto;
