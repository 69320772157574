import React from "react";
import PageTitle from "../../Component/Comman/PageTitle";
import { marketCardData } from "../../Component/Data/MarketData/MarketData";
import MarketTabing from "../../Component/Market/MarketTabing";
import TopSmallCard from "../../Component/Market/TopSmallCard";

// Baskets Page
function Market() {
  return (
    <>
      <div className="body-header border-bottom d-flex py-4 mb-3">
        <h4 className="m-0" style={{ color: "#002040FF" }}>
          Baskets
        </h4>
      </div>
      <div className="container-xxl">
        {/* <div className="row g-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 mb-3">
          {marketCardData.map((d, i) => {
            return (
              <TopSmallCard
                header={d.header}
                coin={d.coin}
                color={d.color || "#96b0b7"}
                rate={d.rate}
                doller={d.doller}
                percentag={d.percentag}
                key={"s" + i}
              />
            );
          })}
        </div> */}
        <MarketTabing />
      </div>
    </>
  );
}

export default Market;
