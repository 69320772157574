import React from "react";
import MarketTabingv2 from "../../../Component/Market/MarketTabingv2";

const MarketCapitalization = () => {
  return (
    <section
      className="tradingview-widget-one light-blue py-5"
      id="market"
      style={{ scrollMarginTop: "80px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="section-title section-home-market"
              style={{ marginBottom: "-5%", width: "115%", marginLeft: "-7%" }}
            >
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                The Future of Crypto Investment Begins Here
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Optima Baskets give anyone access to the digital asset market
                while combining traditional financial infrastructure with DeFi
                composability. It automatically diversifies investments across
                various crypto segments or strategies, reducing cost and
                volatility.
              </p>
            </div>
          </div>
        </div>
        <MarketTabingv2 />
      </div>
    </section>
  );
};

export default MarketCapitalization;
