import React, { useState } from "react";
import BalanceDetail from "../../Component/DashboardOne/BalanceDetail";
import BalanceDetails from "../../Component/WalletPage/BalanceDetails";
import Deposit from "../../Component/WalletPage/Deposit";
import Transaction from "../../Component/WalletPage/Transaction";
import Transfer from "../../Component/WalletPage/Transfer";
import WithdrawCrypto from "../../Component/WalletPage/WithdrawCrypto";
import PageTitle from "../../Component/Comman/PageTitle";

// Wallet Page
function WalletPage() {
  const [activeTab, setActiveTab] = useState("balance"); // Options: 'balance', 'withdraw', 'deposit'

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "balance":
        return <BalanceDetails />;
      case "withdraw":
        return <WithdrawCrypto />;
      case "deposit":
        return <Deposit />;
      default:
        return <BalanceDetails />;
    }
  };

  return (
    <div className="container-xxl">
      <div className="row g-3 mb-3 row-deck">
        <div
          className="border-bottom d-flex py-3 mb-3"
          style={{ color: "#002040FF" }}
        >
          <h4>Wallet Overview</h4>
        </div>

        {/* Tab Navigation */}
        <div className="d-flex gap-3 mb-3">
          <button
            className={`btn ${
              activeTab === "balance" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setActiveTab("balance")}
          >
            Balance Details
          </button>
          <button
            className={`btn ${
              activeTab === "withdraw" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setActiveTab("withdraw")}
          >
            Withdraw
          </button>
          <button
            className={`btn ${
              activeTab === "deposit" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setActiveTab("deposit")}
          >
            Deposit
          </button>
        </div>

        {/* Component Container with consistent styling */}
        <div className="card">
          <div className="card-body p-4">{renderActiveComponent()}</div>
          <Transaction />
        </div>
      </div>

      {/* <div className="row"></div> */}
    </div>
  );
}
export default WalletPage;
