import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import flag from "../../assets/images/flag/GB.png";
import Profile from "../../assets/images/profile_av.svg";
import P1 from "../../assets/images/xs/avatar1.svg";
import P2 from "../../assets/images/xs/avatar3.svg";
import P3 from "../../assets/images/xs/avatar5.svg";
import P4 from "../../assets/images/xs/avatar6.svg";
import P5 from "../../assets/images/xs/avatar7.svg";
import F1 from "../../assets/images/flag/GB.png";
import F2 from "../../assets/images/flag/DE.png";
import F3 from "../../assets/images/flag/FR.png";
import F4 from "../../assets/images/flag/IT.png";
import F5 from "../../assets/images/flag/RU.png";
import QrCode from "../../assets/images/qr-code.png";
import { Link } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserProfile } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";

function Header(props) {
  const { onModalOpen, GotoChangeMenu } = props;
  const [collapse, setCollapse] = useState(false);
  const [collapsebig, setCollapsebig] = useState(false);
  const [sidebarMini, setSidebarMini] = useState(false);
  const { signOut } = useClerk();
  const { isSignedIn, isLoaded, getToken } = useAuth();

  useEffect(() => {
    const checkToken = async () => {
      if (isSignedIn) {
        try {
          const token = await getToken({ template: "optima" });
          console.log("Current user token:", token);
        } catch (err) {
          console.error("Error fetching token:", err);
        }
      }
    };

    checkToken();
  }, [isSignedIn, getToken]);

  if (!isLoaded) {
    return null;
  }

  const closeCommanSidebar = () => {
    var sidebar = document.getElementById("commansidebar");
    if (sidebar) {
      if (sidebar.classList.contains("d-none")) {
        sidebar.classList.remove("d-none");
      } else {
        sidebar.classList.add("d-none");
      }
    }
  };
  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
            <div className="dropdown notifications">
              <span
                className="py-2 px-3 font-weight-bold"
                style={{ color: "#002040FF" }}
              >
                User Profile
              </span>
            </div>
            <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center mx-2">
              <UserButton afterSignOutUrl="/sign-in" />
            </div>
          </div>
          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            type="button"
            onClick={() => {
              var sidebar = document.getElementById("mainsidemenu");
              if (sidebar) {
                if (sidebar.classList.contains("open")) {
                  sidebar.classList.remove("open");
                } else {
                  sidebar.classList.add("open");
                }
              }
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 d-flex align-items-center"></div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
