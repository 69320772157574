import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthRight from "./AuthRight";
import { useSignUp, useClerk } from "@clerk/clerk-react";

function Verification() {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { signUp } = useSignUp();
  const navigate = useNavigate();
  const { setActive } = useClerk();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code: verificationCode,
      });

      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
        navigate("/");
      } else {
        setError("Verification failed. Please try again.");
      }
    } catch (err) {
      console.error("Error during verification:", err);
      setError(err.message || "Verification failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await signUp.prepareEmailAddressVerification();
      alert("New verification code has been sent to your email");
    } catch (err) {
      setError("Failed to resend verification code");
    }
  };

  return (
    <>
      <div className="body d-flex p-0 p-xl-5">
        <div className="container">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-6 d-flex justify-content-center align-items-center ">
              <div className="d-flex flex-column">
                <h1>Verification</h1>
                <span className="text-muted">
                  We sent a verification code to your email or phone. <br />
                  Enter the code from the field below.
                </span>
                <div className="card mt-4 mb-3" style={{ maxWidth: "30rem" }}>
                  <div className="card-body p-4">
                    <form onSubmit={handleSubmit} className="row g-1">
                      <div className="col">
                        <div className="mb-2">
                          <input
                            type="text"
                            className="form-control form-control-lg text-center"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            placeholder="-"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-12 text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary text-uppercase py-2 fs-5 w-100"
                        >
                          {isLoading ? "Verifying..." : "Verify my account"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <Link
                  to={process.env.PUBLIC_URL + "#!"}
                  title="#"
                  className="text-primary text-decoration-underline"
                >
                  Resend a new code?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verification;
