import React from "react";

const FAQSection = () => {
  const faqItems = [
    {
      id: "1",
      question: "What is Optima?",
      answer: (
        <>
          <p>
            Optima is an investment platform that tokenizes cryptocurrency
            investment strategies in a secure and compliant way. Each Optima
            basket token reflects a specific investment strategy, ranging from
            passive to active, offering retail and institutional investors
            customized access to the crypto market based on their risk
            tolerance.
          </p>
          <p>
            Optima is the first to deliver tokenized crypto investment
            strategies that are compliant and freely tradable on decentralized
            blockchain networks.
          </p>
        </>
      ),
    },
    {
      id: "2",
      question: "Who is Optima for?",
      answer: (
        <>
          <p>
            The Optima platform unlocks investment opportunities for traditional
            retail investors and institutional participants—including banks,
            hedge funds, broker-dealers, and asset managers—seeking
            cost-efficient and diversified access to cryptocurrency investment
            strategies.
          </p>
          <p>
            For on-chain investors, Optima baskets provide a cheap and efficient
            way to diversify holdings, whether for individual portfolios or DAO
            treasuries. These baskets seamlessly integrate with Layer 1/Layer 2
            blockchain ecosystems and centralized exchanges.
          </p>
          <p>
            All individuals and organizations joining the Optima platform must
            comply with our Know Your Customer (KYC) and Anti-Money Laundering
            (AML) standards.
          </p>
        </>
      ),
    },
    {
      id: "3",
      question: "What makes Optima different?",
      answer: (
        <>
          <p>
            Optima baskets are the only tokenized investment products backed by
            a prospectus, available to both retail and institutional investors.
            They provide a cost-efficient and secure way to invest in
            cryptocurrency baskets within a regulatory-compliant framework,
            while preserving key DeFi benefits such as peer-to-peer
            functionality, self-custody, on-chain transparency, and networked
            liquidity.
          </p>
          <p>
            Built with compliance at its core, Optima basket tokens integrate
            seamlessly with both crypto platforms and traditional financial
            apps. This hybrid approach merges the efficiency and transparency of
            blockchain trading with the reliability and investment approach of
            traditional markets.
          </p>
        </>
      ),
    },
    {
      id: "4",
      question: "How does Optima make money?",
      answer: (
        <>
          <p>Optima generates revenue via:</p>
          <ul>
            <li>
              <strong>Minting/Burning Fees</strong> which are charged in
              stablecoins at the creation and redemption of the basket token
            </li>
            <li>
              <strong>Management Fees</strong> charged daily on the value of
              basket tokens under management
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "11",
      question:
        "Do I need crypto to buy tokenized investment baskets on Optima?",
      answer: (
        <>
          <p>
            At launch, users will need to purchase Optima basket tokens using
            USDC on Base.
          </p>
        </>
      ),
    },
    {
      id: "22",
      question: "Can I buy these tokens with fiat?",
      answer:
        "Not yet, Optima may release this functionality in subsequent releases.",
    },
    {
      id: "33",
      question: "How risky is it to invest in Optima basket tokens?",
      answer: (
        <>
          <p>
            Investing in Optima basket tokens carries risk, as all investments
            in the cryptocurrency market are volatile. While Optima provides a
            range of investment opportunities designed to cater to different
            risk appetites—from conservative to aggressive—all basket tokens are
            subject to the risks associated with the crypto market, including
            price fluctuations and market dynamics.
          </p>
          <p>
            We encourage investors to carefully assess their risk tolerance and
            diversify their portfolios to manage potential downsides
            effectively.
          </p>
        </>
      ),
    },
    {
      id: "44",
      question:
        "How do I 'know' my tokens are truly backed by real world assets?",
      answer:
        "Basket tokens are fully backed by their underlying assets, which are securely held by regulated custodians. Investors can redeem tokens at any time for the value of the underlying assets. Monthly disclosures detailing the assets in reserve will be publicly available, and Optima is actively working toward implementing a real-time asset verification system.",
    },
  ];

  return (
    <section className="faq section light-blue">
      <div className="container light-blue">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                How can we help you?
              </h2>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="accordion" id="accordionExample">
              {faqItems.slice(0, 4).map((item) => (
                <div className="accordion-item" key={item.id}>
                  <h2 className="accordion-header" id={`heading${item.id}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${item.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${item.id}`}
                    >
                      <span className="title">{item.question}</span>
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                  <div
                    id={`collapse${item.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${item.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="accordion" id="accordionExample2">
              {faqItems.slice(4).map((item) => (
                <div className="accordion-item" key={item.id}>
                  <h2 className="accordion-header" id={`heading${item.id}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${item.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${item.id}`}
                    >
                      <span className="title">{item.question}</span>
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                  <div
                    id={`collapse${item.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${item.id}`}
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
