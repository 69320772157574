import spotImg1 from "../../../assets/images/coin/ADA.png";
import spotImg2 from "../../../assets/images/coin/BTC.png";
import spotImg3 from "../../../assets/images/coin/DASH.png";
import spotImg4 from "../../../assets/images/coin/COTI.png";
import spotImg5 from "../../../assets/images/coin/ETH.png";
import spotImg6 from "../../../assets/images/coin/SOL.png";
import spotBnb from "../../../assets/images/coin/CAKE.png";
import spotALTSBNB from "../../../assets/images/coin/BNB.png";
import spotALTSBTT from "../../../assets/images/coin/BTS.png";
import spotALTSCNX from "../../../assets/images/coin/CNX.png";
import spotALTSHEGIC from "../../../assets/images/coin/HSR.png";
import spotALTSTRX from "../../../assets/images/coin/TRX.png";
import spotALTSWIN from "../../../assets/images/coin/WTC.png";
import CELR from "../../../assets/images/coin/CVC.png";
import Magic from "../../../assets/images/coin/MATIC.png";
import USDT from "../../../assets/images/coin/USDT.png";
import Gala from "../../../assets/images/coin/GALA.png";
import ADX from "../../../assets/images/coin/ADX.png";
import ARDR from "../../../assets/images/coin/ARDR.png";
import ARK from "../../../assets/images/coin/ARK.png";
import BAT from "../../../assets/images/coin/BAT.png";
import BCH from "../../../assets/images/coin/BCH.png";
import BNB from "../../../assets/images/coin/BNB.png";
import BNT from "../../../assets/images/coin/BNT.png";
import BTC from "../../../assets/images/coin/BTC.png";
import ChangeCell from "../../ChangeCell/ChangeCellBlackText";

export const marketCardData = [
  {
    header: "Top gainer (24h)",
    coin: "DF/USDT",
    rate: "0.3165",
    doller: "$0",
    percentag: "+59.10%",
    color: true,
  },
  {
    header: "TOP LOSER (24H)",
    coin: "XTZDOWN/USDT",
    rate: "2.831",
    doller: "$3",
    percentag: "-40.87%",
    color: false,
  },
  {
    header: "HIGHLIGHT",
    coin: "USDT/BIDR",
    rate: "14,339",
    doller: "$1",
    percentag: "-0.44% ",
  },
  {
    header: "GRT/USDT",
    coin: "DOT/USDT",
    rate: "30.90",
    doller: "$31",
    percentag: "+3.66%",
    color: true,
  },
];

export const favoriteSpotData = {
  columns: [
    {
      name: "Thematic Basket Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Basket Symbol",
      selector: (row) => row.basket_symbol,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "Current",
      selector: (row) => row.current,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "1d Chg",
      selector: (row) => row.Chg1d,
      sortable: true,

      cell: (row) => <ChangeCell value={row.Chg1d} />,
    },
    {
      name: "7d Chg",
      selector: (row) => row.Chg7d,
      sortable: true,

      cell: (row) => <ChangeCell value={row.Chg7d} />,
    },
    {
      name: "1m Chg",
      selector: (row) => row.Chg30d,
      sortable: true,

      cell: (row) => <ChangeCell value={row.Chg30d} />,
    },
    {
      name: "Chart",
      selector: (row) => row.image,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
      cell: (row) => (
        <a className="fw-bold">
          {/* Removing href="/baskets" to make it inactive for now */}
          <img src={row.image} alt="" className="img-fluid" />
        </a>
      ),
    },
    {
      name: "Trade",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a className="fw-bold trade-btn">
          {/* Removing href="/baskets" to make it inactive for now */}
          {row.action}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "BNB",
      price: "0.006640 / $2.35",
      hchange: "+7.27%",
      h7change: "+2.30%",
      h30change: "-1.30%",
      aciton: "Trade",
    },
    {
      image: spotImg2,
      coinnamefirst: "BTC",
      coinnamesecond: "USDT",
      price: "42,564.39 / $42,350.69",
      hchange: "+1.12%",
      h7change: "+4.18%",
      h30change: "-0.57%",
      aciton: "Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.08686 / $2.35",
      hchange: "+3.45%",
      h7change: "+0.95%",
      h30change: "-1.25%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.0018329 / $0.64",
      hchange: "+28.33%",
      h7change: "+7.02%",
      h30change: "-2.85%",
      aciton: "Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "ETH",
      coinnamesecond: "USDT",
      price: "2,918.14 / $2,916.23",
      hchange: "+4.03%",
      h7change: "+3.12%",
      h30change: "-0.98%",
      aciton: "Trade",
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      coinnamesecond: "USDT",
      price: "139.15 / $139.22",
      hchange: "+5.83%",
      h7change: "+1.73%",
      h30change: "-3.27%",
      aciton: "Trade",
    },
  ],
};

export const favoritefutureData = {
  columns: [
    {
      name: "Thematic Basket Name",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          <div style={{ width: "100px" }}>{row.coinnamefirst}</div>
          <span className="text-muted" style={{ width: "100px" }}>
            /perpetual
          </span>
        </>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Basket Sybol",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "Current",
      selector: (row) => row.price,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "1d Chg",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7d Chg",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "1m Chg",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "Chart",
      selector: (row) => {},
      sortable: true,
      cell: () => (
        <a href="/baskets" title="" className="trade-btn trade-btn">
          Trade
        </a>
      ),
    },
    {
      name: "Trade",
      selector: (row) => {},
      sortable: true,
      cell: () => (
        <a href="/baskets" title="" className="trade-btn trade-btn">
          Trade
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADAUSDT",
      price: "0.006640 / $2.35",
      hchange: "+7.27%",
      h7change: "+2.30%",
      h30change: "-1.30%",
    },
    {
      image: spotImg2,
      coinnamefirst: "BTCUSDT",
      price: "42,564.39 / $42,350.69",
      hchange: "+1.12%",
      h7change: "+4.18%",
      h30change: "-0.57%",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTIUSDT",
      price: "0.08686 / $2.35",
      hchange: "+3.45%",
      h7change: "+0.95%",
      h30change: "-1.25%",
    },
    {
      image: spotImg4,
      coinnamefirst: "COTIUSDT",
      price: "0.0018329 / $0.64",
      hchange: "+28.33%",
      h7change: "+7.02%",
      h30change: "-2.85%",
    },
    {
      image: spotImg5,
      coinnamefirst: "ETHUSDT",
      price: "2,918.14 / $2,916.23",
      hchange: "+4.03%",
      h7change: "+3.12%",
      h30change: "-0.98%",
    },
    {
      image: spotImg6,
      coinnamefirst: "SOLUSDT",
      price: "139.15 / $139.22",
      hchange: "+5.83%",
      h7change: "+1.73%",
      h30change: "-3.27%",
    },
  ],
};

export const favoriteMarginData = {
  columns: [
    {
      name: "Thematic Basket Name",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Basket Symbol",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "Current",
      selector: (row) => row.price,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "1d Chg",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7d Chg",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "1m Chg",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "Chart",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn trade-btn">
          {row.aciton}
        </a>
      ),
    },
    {
      name: "Trade",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg4,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.0018329 / $0.64",
      hchange: "+28.33%",
      h7change: "+7.02%",
      h30change: "-2.85%",
      aciton: "Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "ETH",
      coinnamesecond: "USDT",
      price: "2,918.14 / $2,916.23",
      hchange: "+4.03%",
      h7change: "+3.12%",
      h30change: "-0.98%",
      aciton: "Trade",
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      coinnamesecond: "USDT",
      price: "139.15 / $139.22",
      hchange: "+5.83%",
      h7change: "+1.73%",
      h30change: "-3.27%",
      aciton: "Trade",
    },
  ],
};

export const soptMarkebnb = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "BNB",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "+7.27%",
      h7change: "+1.32%",
      h30change: "-0.98%",
      aciton: "Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "+3.56%",
      h30change: "-1.34%",
      aciton: "Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+2.01%",
      h30change: "-0.65%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "MATIC",
      coinnamesecond: "BNB",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "+8.25%",
      h30change: "-3.50%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotBnb,
      coinnamefirst: "CAKE",
      coinnamesecond: "BNB",
      price: "2,918.14 /",
      dollerprice: "  $2,916.23",
      hchange: "+4.03%",
      h7change: "+2.10%",
      h30change: "-1.47%",
      aciton: "Trade",
      pricedown: true,
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      coinnamesecond: "BNB",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+1.90%",
      h30change: "-2.72%",
      aciton: "Trade",
      redtext: true,
    },
  ],
};

export const soptMarkeBTC = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className={`fa fa-star px-2 fa-lg ${
              row.iconcolor ? "text-warning" : ""
            }`}
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "BNB",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "-3.46%",
      h7change: "-1.56%",
      h30change: "-4.23%",
      aciton: "Trade",
      greentext: true,
      pricedown: true,
    },
    {
      image: spotBnb,
      coinnamefirst: "CAKE",
      coinnamesecond: "BNB",
      price: "2,918.14 / ",
      dollerprice: "$2,916.23",
      hchange: "+4.03%",
      h7change: "+2.10%",
      h30change: "-1.47%",
      aciton: "Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.08686 / ",
      dollerprice: "$2.35",
      hchange: "+3.45%",
      h7change: "+1.90%",
      h30change: "-2.10%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "MATIC",
      coinnamesecond: "BNB",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "+5.20%",
      h30change: "+12.00%",
      aciton: "Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "+0.56%",
      h30change: "-1.23%",
      aciton: "Trade",
      greentext: true,
    },
    {
      image: spotImg3,
      coinnamefirst: "MATIC",
      coinnamesecond: "BNB",
      price: "0.0018329 /",
      dollerprice: "  $0.64",
      hchange: "+28.33%",
      h7change: "+7.50%",
      h30change: "+8.00%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      coinnamesecond: "BNB",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+2.25%",
      h30change: "-3.00%",
      aciton: "Trade",
      redtext: true,
    },
  ],
};

export const soptMarketALTS = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotALTSBNB,
      coinnamefirst: "ADA",
      coinnamesecond: "BNB",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "+27.27%",
      h7change: "+2.00%",
      h30change: "+5.45%",
      aciton: "Trade",
    },
    {
      image: spotALTSBTT,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "-0.50%",
      h30change: "+0.90%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotALTSCNX,
      coinnamefirst: "COTI",
      coinnamesecond: "BNB",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+1.75%",
      h30change: "+2.60%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotALTSHEGIC,
      coinnamefirst: "MATIC",
      coinnamesecond: "BNB",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "+8.45%",
      h30change: "+10.25%",
      aciton: "Trade",
    },
    {
      image: spotALTSTRX,
      coinnamefirst: "CAKE",
      coinnamesecond: "BNB",
      price: "2,918.14 /",
      dollerprice: "  $2,916.23",
      hchange: "+4.03%",
      h7change: "+1.80%",
      h30change: "+3.50%",
      aciton: "Trade",
    },
    {
      image: spotALTSWIN,
      coinnamefirst: "SOL",
      coinnamesecond: "BNB",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+3.45%",
      h30change: "+4.50%",
      aciton: "Trade",
      greentext: true,
    },
  ],
};

export const soptMarketFAIT = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className={`fa fa-star px-2 fa-lg ${
              row.iconcolor ? "text-warning" : ""
            }`}
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "BTC",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "-3.46%",
      h7change: "-2.00",
      h30change: "-4.25",
      aciton: "Trade",
      greentext: true,
      pricedown: true,
    },
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "GBP",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "+0.27%",
      h7change: "+0.50%",
      h30change: "+1.20%",
      aciton: "Trade",
      greentext: true,
      pricedown: true,
    },
    {
      image: spotBnb,
      coinnamefirst: "CAKE",
      coinnamesecond: "BTC",
      price: "2,918.14 /",
      dollerprice: "  $2,916.23",
      hchange: "+4.03%",
      h7change: "+2.25%",
      h30change: "+3.50%",
      aciton: "Trade",
    },
    {
      image: CELR,
      coinnamefirst: "CELR",
      coinnamesecond: "USDT",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "+0.75%",
      h30change: "+1.25%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "BTC",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+2.10%",
      h30change: "+3.60%",
      aciton: "Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "USDT",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+1.90%",
      h30change: "+3.20%",
      aciton: "Trade",
    },
    {
      image: spotImg2,
      coinnamefirst: "DOT",
      coinnamesecond: "BTC",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "+10.00%",
      h30change: "+15.50%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "ETH",
      coinnamesecond: "BTC",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+3.75%",
      h30change: "+4.80%",
      aciton: "Trade",
      iconcolor: true,
      greentext: true,
    },
    {
      image: spotImg4,
      coinnamefirst: "ETH",
      coinnamesecond: "EUR",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+3.50%",
      h30change: "+5.00%",
      aciton: "Trade",
      iconcolor: true,
      greentext: true,
    },
    {
      image: spotImg4,
      coinnamefirst: "ETH",
      coinnamesecond: "USDT",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+3.60%",
      h30change: "+4.85%",
      aciton: "Trade",
      iconcolor: true,
      greentext: true,
    },
  ],
};

export const soptMarketETF = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className={`fa fa-star px-2 fa-lg ${
              row.iconcolor ? "text-warning" : ""
            }`}
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADADOWN",
      coinnamesecond: "USDT",
      price: "0.006640 / $2.35",
      hchange: "-3.46%",
      h7change: "+1.23%",
      h30change: "-2.45%",
      aciton: "Trade",
      greentext: true,
      pricedown: true,
    },
    {
      image: spotBnb,
      coinnamefirst: "CAKE",
      coinnamesecond: "USDT",
      price: "2,918.14 / $2,916.23",
      hchange: "+4.03%",
      h7change: "-0.78%",
      h30change: "+3.56%",
      aciton: "Trade",
    },
    {
      image: CELR,
      coinnamefirst: "CELR",
      coinnamesecond: "USDT",
      price: "42,564.39 / $42,350.69",
      hchange: "+1.12%",
      h7change: "+2.34%",
      h30change: "-1.67%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      coinnamesecond: "USDT",
      price: "0.08686 / $2.35",
      hchange: "+3.45%",
      h7change: "-1.89%",
      h30change: "+4.23%",
      aciton: "Trade",
    },
    {
      image: spotImg2,
      coinnamefirst: "COTI",
      coinnamesecond: "USDT",
      price: "0.08686 / $2.35",
      hchange: "+3.45%",
      h7change: "+0.56%",
      h30change: "-2.78%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "ETH",
      coinnamesecond: "USDT",
      price: "139.15 / $139.22",
      hchange: "+5.83%",
      h7change: "+3.45%",
      h30change: "+1.23%",
      aciton: "Trade",
      iconcolor: true,
      greentext: true,
    },
    {
      image: Magic,
      coinnamefirst: "ETH",
      coinnamesecond: "USDT",
      price: "0.00315 / $139.22",
      hchange: "+5.83%",
      h7change: "-0.67%",
      h30change: "+2.89%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      coinnamesecond: "USDT",
      price: "139.15 / $139.22",
      hchange: "+5.83%",
      h7change: "+1.45%",
      h30change: "-3.12%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: USDT,
      coinnamefirst: "SOL",
      coinnamesecond: "USDT",
      price: "72.15 / $139.22",
      hchange: "+5.83%",
      h7change: "-2.34%",
      h30change: "+0.78%",
      aciton: "Trade",
      redtext: true,
    },
  ],
};

export const feutureMarketUSD = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/perpetual</span>
        </>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "+7.27%",
      h7change: "+3.45%",
      h30change: "-2.18%",
      aciton: " Trade",
      redtext: true,
    },
    {
      image: spotImg2,
      coinnamefirst: "BTC",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "-1.20%",
      h30change: "+5.67%",
      aciton: " Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+6.78%",
      h30change: "-3.90%",
      aciton: " Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "COTI",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "-0.89%",
      h30change: "+4.56%",
      aciton: " Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "ETH",
      price: "2,918.14 /",
      dollerprice: " $2,916.23",
      hchange: "+4.03%",
      h7change: "+2.34%",
      h30change: "-1.78%",
      aciton: " Trade",
      greentext: true,
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+1.56%",
      h30change: "-0.67%",
      aciton: " Trade",
      redtext: true,
    },
  ],
};

export const feutureMarketCOIN = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/perpetual</span>
        </>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price + row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      price: "0.006640 /",
      dollerprice: " $2.35",
      hchange: "+7.27%",
      h7change: "+3.45%",
      h30change: "-2.18%",
      aciton: "Trade",
      redtext: true,
    },
    {
      image: spotImg2,
      coinnamefirst: "BTC",
      price: "42,564.39 /",
      dollerprice: " $42,350.69",
      hchange: "+1.12%",
      h7change: "-1.20%",
      h30change: "+5.67%",
      aciton: "Trade",
    },
    {
      image: spotImg3,
      coinnamefirst: "COTI",
      price: "0.08686 /",
      dollerprice: " $2.35",
      hchange: "+3.45%",
      h7change: "+6.78%",
      h30change: "-3.90%",
      aciton: "Trade",
    },
    {
      image: spotImg4,
      coinnamefirst: "COTI",
      price: "0.0018329 /",
      dollerprice: " $0.64",
      hchange: "+28.33%",
      h7change: "-0.89%",
      h30change: "+4.56%",
      aciton: "Trade",
    },
    {
      image: spotImg5,
      coinnamefirst: "ETH",
      price: "2,918.14 /",
      dollerprice: " $2,916.23",
      hchange: "+4.03%",
      h7change: "+2.34%",
      h30change: "-1.78%",
      aciton: "Trade",
      greentext: true,
    },
    {
      image: spotImg6,
      coinnamefirst: "SOL",
      price: "139.15 /",
      dollerprice: " $139.22",
      hchange: "+5.83%",
      h7change: "+1.56%",
      h30change: "-0.67%",
      aciton: "Trade",
      redtext: true,
    },
  ],
};

export const newListing = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          {" "}
          <i
            className="fa fa-star px-2 fa-lg text-warning"
            aria-hidden="true"
          ></i>{" "}
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">/GALA</span>
        </>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: Gala,
      coinnamefirst: "GALA",
      price: "$0.09",
      hchange: "+5.83%",
      h7change: "+1.56%",
      h30change: "-0.67%",
      aciton: "Trade",
    },
  ],
};

export const AllCripto = {
  columns: [
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinnamefirst}
          <span className="text-muted">{row.coinnamesecond}</span>
        </>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "SYMBOL",
      selector: (row) => row.coinnamefirst,
      sortable: true,
      maxWidth: "50px",
      minWidth: "120px",
    },
    {
      name: "PRICE",
      selector: (row) => row.dollerprice,
      sortable: true,
      maxWidth: "50px",
      minWidth: "160px",
    },
    {
      name: "24H CHANGE",
      selector: (row) => row.hchange,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.hchange} />,
    },
    {
      name: "7D CHANGE",
      selector: (row) => row.h7change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h7change} />,
    },
    {
      name: "30D CHANGE",
      selector: (row) => row.h30change,
      sortable: true,
      style: {
        padding: "0",
      },
      cell: (row) => <ChangeCell value={row.h30change} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.aciton,
      sortable: true,
      cell: (row) => (
        <a href="/baskets" className="fw-bold trade-btn">
          {row.aciton}
        </a>
      ),
    },
  ],
  rows: [
    {
      image: spotImg1,
      coinnamefirst: "ADA",
      coinnamesecond: "Cardano",
      dollerprice: " $2.25",
      hchange: "-3.46%",
      h7change: "+1.23%",
      h30change: "-2.45%",
      aciton: " Trade",
    },
    {
      image: ADX,
      coinnamefirst: "ADX",
      coinnamesecond: "Adex",
      dollerprice: "	$0.46",
      hchange: "+4.03%",
      h7change: "-0.78%",
      h30change: "+3.21%",
      aciton: " Trade",
    },
    {
      image: ARDR,
      coinnamefirst: "ARDR",
      coinnamesecond: "Ardor",
      dollerprice: "$0.28",
      hchange: "+3.45%",
      h7change: "+2.67%",
      h30change: "-1.89%",
      aciton: " Trade",
    },
    {
      image: ARK,
      coinnamefirst: "ARK",
      coinnamesecond: "Ark",
      dollerprice: " $1.51",
      hchange: "-1.97%",
      h7change: "-0.56%",
      h30change: "+4.32%",
      aciton: " Trade",
      pricedown: true,
    },
    {
      image: BAT,
      coinnamefirst: "BAT",
      coinnamesecond: "Attention Token",
      dollerprice: "$0.65",
      hchange: "+1.12%",
      h7change: "+3.45%",
      h30change: "-2.10%",
      aciton: " Trade",
    },
    {
      image: BCH,
      coinnamefirst: "BCH",
      coinnamesecond: "Bitcoin Cash",
      dollerprice: " $511.30",
      hchange: "+28.33%",
      h7change: "+5.67%",
      h30change: "+1.23%",
      aciton: " Trade",
    },
    {
      image: BNB,
      coinnamefirst: "BNB",
      coinnamesecond: "BNB",
      dollerprice: "	$355.80",
      hchange: "+5.83%",
      h7change: "-1.34%",
      h30change: "+6.78%",
      aciton: " Trade",
    },
    {
      image: BNT,
      coinnamefirst: "BNT",
      coinnamesecond: "Bancor",
      dollerprice: "	$3.60",
      hchange: "+28.33%",
      h7change: "+2.89%",
      h30change: "-3.45%",
      aciton: " Trade",
      redtext: true,
    },
    {
      image: BTC,
      coinnamefirst: "BTC",
      coinnamesecond: "Bitcoin",
      dollerprice: " 	$44,090.69",
      hchange: "+5.83%",
      h7change: "+4.56%",
      h30change: "+0.98%",
      aciton: " Trade",
    },
    {
      image: CELR,
      coinnamefirst: "CVC",
      coinnamesecond: "Civic",
      dollerprice: "$0.43",
      hchange: "+5.83%",
      h7change: "-2.34%",
      h30change: "+5.67%",
      aciton: " Trade",
    },
  ],
};
