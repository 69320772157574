import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Qrcode from "../../assets/images/qr-code.png";

function Deposit() {
  const [selectedCrypto, setSelectedCrypto] = useState("USDC");
  const [selectedNetwork, setSelectedNetwork] = useState("Ethereum(ERC20)");

  const cryptoOptions = ["USDC"]; //  "ETH", "USDT", "BNB", "EOS", "SOL"
  const networkOptions = [
    "Ethereum(ERC20)",
    "Cryptoon(CRC20)",
    "SmartC(SEP20)",
    "Solana",
    "Tron(TRC20)",
    "Ethereum(ERC30)",
  ];

  return (
    <div className="container-fluid p-4">
      <Tab.Container defaultActiveKey="crypto">
        <div className="row g-4">
          {/* Tab Navigation */}
          <div className="col-12">
            <Nav className="nav-tabs border-0 flex-row gap-3">
              <Nav.Item>
                <Nav.Link eventKey="crypto" className="btn btn-outline-primary">
                  Crypto
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cash" className="btn btn-outline-primary">
                  Cash
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          {/* Tab Content */}
          <div className="col-12">
            <Tab.Content>
              {/* Crypto Deposit */}
              <Tab.Pane eventKey="crypto">
                <div className="row g-4">
                  {/* Crypto Selection */}
                  <div className="col-12">
                    <label className="form-label text-muted mb-3">
                      Select Cryptocurrency
                    </label>
                    <div className="d-flex flex-wrap gap-3">
                      {cryptoOptions.map((crypto) => (
                        <div
                          key={crypto}
                          className={`btn ${
                            selectedCrypto === crypto
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => setSelectedCrypto(crypto)}
                        >
                          {crypto}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Network Selection */}
                  <div className="col-12">
                    <label className="form-label text-muted mb-3">
                      Select Network
                    </label>
                    <div className="d-flex flex-wrap gap-3">
                      {networkOptions.map((network) => (
                        <div
                          key={network}
                          className={`btn ${
                            selectedNetwork === network
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => setSelectedNetwork(network)}
                        >
                          {network}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* QR Code and Details */}
                  <div className="col-12">
                    <div className="row align-items-center g-4">
                      <div className="col-md-4">
                        <img
                          src={Qrcode}
                          alt="QR Code"
                          className="img-fluid rounded"
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="p-3 border rounded">
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="text-muted mb-1">
                                Minimum Deposit
                              </div>
                              <div className="fw-bold">
                                0.0005086 {selectedCrypto}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="text-muted mb-1">
                                Expected Arrival
                              </div>
                              <div className="fw-bold">1 network confirm</div>
                            </div>
                            <div className="col-sm-6">
                              <div className="text-muted mb-1">
                                Expected Unlock
                              </div>
                              <div className="fw-bold">1 network confirm</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>

              {/* Cash Deposit */}
              <Tab.Pane eventKey="cash">
                <div className="row g-4">
                  <div className="col-12">
                    <p className="text-muted">
                      Deposit amount from your bank account and receive funds in{" "}
                      <span className="text-primary">USD</span>
                    </p>
                  </div>

                  {/* Currency Selection */}
                  <div className="col-12">
                    <label className="form-label text-muted mb-2">
                      Select Currency
                    </label>
                    <select className="form-select">
                      {["INR", "AED", "ARS", "IDR", "TRY", "USD"].map(
                        (currency) => (
                          <option key={currency} value={currency}>
                            {currency}
                          </option>
                        )
                      )}
                    </select>
                  </div>

                  {/* Payment Method */}
                  <div className="col-12">
                    <label className="form-label text-muted mb-2">
                      Pay with
                    </label>
                    <div className="d-flex flex-column gap-3">
                      <div className="form-check p-3 border rounded">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="bankCard"
                          defaultChecked
                        />
                        <label className="form-check-label" htmlFor="bankCard">
                          Bank Card (Visa/MC){" "}
                          <span className="text-muted">1.8% fee</span>
                        </label>
                      </div>
                      <div className="form-check p-3 border rounded">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="accountBalance"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="accountBalance"
                        >
                          Account Balance{" "}
                          <span className="text-muted">0% fee</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary w-100 py-3 text-uppercase fw-bold"
            >
              Confirm Deposit
            </button>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}

export default Deposit;
