import React, { useState } from "react";
import { Dropdown, Nav, Tab, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthRight from "./AuthRight";
import { useSignUp, useClerk } from "@clerk/clerk-react";

function Signup() {
  const [selectedCountry, setSelectedCountry] = useState("+91 India");

  const countries = [
    { code: "+376", name: "Andora" },
    { code: "+61", name: "Australia" },
    { code: "+55", name: "Brazil" },
    { code: "+91", name: "India" },
  ];

  const clerk = useClerk();

  const { signUp } = useSignUp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setPassword("");
    setRepeatPassword("");

    if (password !== repeatPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await signUp.create({
        emailAddress: email,
        password,
      });

      await signUp.prepareEmailAddressVerification();
      navigate("/verification");
    } catch (err) {
      console.error("Error during sign-up:", err);
      if (err.errors && Array.isArray(err.errors)) {
        const errorMessage =
          err.errors[0]?.longMessage || err.errors[0]?.message;
        setError(errorMessage || "An error occurred during sign up");
      } else if (err.message) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setError("");
    setSuccessMessage("");
  };

  return (
    <>
      <div className="body d-flex p-0 p-xl-5">
        <div className="container">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-5">
              <div className="d-flex flex-column align-items-start">
                <h1>Create Your Account</h1>
                <span className="text-muted">
                  Register with your email or mobile
                </span>
                <Tab.Container defaultActiveKey="first">
                  <Nav
                    className="nav nav-pills mt-4"
                    role="tablist"
                    style={{ width: "100%" }}
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" eventKey="first">
                        Email
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" eventKey="second">
                        Mobile
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content
                    className="tab-content mt-4 mb-3"
                    style={{ width: "100%" }}
                  >
                    <Tab.Pane
                      className="tab-pane fade"
                      id="Email"
                      eventKey="first"
                    >
                      <div className="card">
                        <div className="card-body border p-0">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label fs-6">
                                Email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label fs-6">
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label fs-6">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                value={repeatPassword}
                                onChange={(e) =>
                                  setRepeatPassword(e.target.value)
                                }
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                            >
                              Create Account
                            </button>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      id="Mobile"
                      eventKey="second"
                    >
                      <div className="card">
                        <div className="card-body p-0">
                          <form>
                            <label className="form-label fs-6">Mobile</label>
                            <Dropdown className="input-group mb-3">
                              <Dropdown.Toggle
                                className="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                              >
                                {selectedCountry}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                {countries.map((country) => (
                                  <Dropdown.Item
                                    key={country.code}
                                    onClick={() =>
                                      setSelectedCountry(
                                        `${country.code} ${country.name}`
                                      )
                                    }
                                  >
                                    {country.code} {country.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                              <input type="text" className="form-control" />
                            </Dropdown>
                            <div className="mb-3">
                              <label className="form-label fs-6">
                                Password
                              </label>
                              <input type="password" className="form-control" />
                            </div>
                            <div className="mb-3">
                              <label className="form-label fs-6">
                                Confirm Password
                              </label>
                              <input type="password" className="form-control" />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                            >
                              sign up
                            </button>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                <Link to={process.env.PUBLIC_URL + "/sign-in"} title="#">
                  Already registered?{" "}
                  <span className="text-secondary text-decoration-underline">
                    Log In
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <Modal show={successMessage !== ""} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={error !== ""} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Signup;
