import React, { useEffect } from "react";
import { SignIn, useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

function Signin() {
  return (
    <div className="body d-flex p-0 p-xl-5">
      <div className="container">
        <div className="row g-3 justify-content-center">
          <div className="col-lg-5">
            <SignIn
              routing="path"
              path="/sign-in"
              signUpUrl="/sign-up"
              appearance={{
                elements: {
                  rootBox: "mx-auto",
                  card: "mx-auto",
                  socialButtonsBlockButton:
                    "btn btn-outline-secondary w-100 mb-3",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
